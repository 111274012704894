<template>
  <section>
    <v-data-table
      :headers="headers"
      :items="reports"
      :loading="isLoading"
      class="elevation-1"
    >
      <template v-slot:[`item.reportName`]="{ item }">
        <router-link :to="`/reports/${item.powerBiReportId}`">{{
          item.reportName
        }}</router-link>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: "ReportsModule",

  async created() {
    let response = await this.axios.get("/v1/viewsupport/report");
    this.reports = response.data.data;
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: true,
      headers: [{ text: "Report Name", value: "reportName" }],

      reports: [],
    };
  },
};
</script>
